import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../components/layout/layout";

import PageContainer from "../../components/layout/page-container";
import PageSection from "../../components/layout/page-section";
import FilterBar from "../../components/browser/filter-bar";

class PerformancesByYearPage extends React.Component {
  constructor({ data }) {
    super();

    this.data = data;
  }

  render() {
    const years = this.data.allPerformancesJson.edges.map(({ node }) => {
      return node.dates[0].substring(0, 4);
    }).filter((year, idx, self) => {
      return self.indexOf(year) === idx;
    });

    return (
      <Layout>
        <PageContainer
          bottomPadding="lg"
        >
          <PageSection background="dark-accent">
            <h1 className="page-title">Performances by Year</h1>
          </PageSection>
          <PageSection topPadding="md">
            <FilterBar filterSet="performances" currentFilter="year" />
            <ul className="listing-grid">
              {years.map(year => (
                <li key={year} className="listing-grid__cell">
                  <Link to={`/performances/years/${year}`}>{year}</Link>
                </li>))}
            </ul>
            <FilterBar filterSet="performances" currentFilter="year" />
          </PageSection>
        </PageContainer>
      </Layout>
    );
  }
}

export default PerformancesByYearPage;

export const query = graphql`
  query PerformancesByYearQuery {
    allPerformancesJson(filter: {reviewed: {eq: true}},
                        sort: {fields: dates, order: ASC}) {
      edges {
        node {
          dates
        }
      }
    }
  }
`